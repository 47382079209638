.title {
  padding: 0.25em 0;
  color: white;
  margin: 0 auto 0 0;
  text-align: center;
}

.titleBg {
  background-color: #4c6d4f;
}

.name {
  padding: 0.25em 0;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #4c6d4f;
  margin-bottom: 0;
}

.row {
  padding: 1em 1em;
}

.col {
  padding-bottom: 1em;
}

@media (min-width: 768px) {
  .title {
    text-align: start;
    padding: 0.5em 0;
  }
}
