.button {
  color: #000000;
  display: inline-flex;
  align-items: center;

  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 1rem;
}

.button:focus {
  outline: none;
}

.button:hover {
  color: #999999;
}

.title {
  margin: 0 !important;
}

.listItemWrapper {
  display: flex;
}

.linkWrapper {
  margin-left: auto;
  color: black;
  text-decoration: none;
  display: inline-flex;
}

.linkContentWrapper {
  display: flex;
  align-items: center;
}

.linkWrapper:hover {
  text-decoration: none;
  color: #999999;
}

.linkContentWrapper p {
  margin: 0;
}

.linkContentWrapper svg {
  margin-right: 4px;
}

.icon {
  margin-right: 0.5em;
}

.buttons {
  margin-right: 2em;
  display: flex;
  align-items: center;
}

@media (max-width: 420px) {
  .linkContentWrapper p {
    display: none;
  }

  .button span {
    display: none;
  }
}
