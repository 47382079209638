.legend-labels {
    margin: 0;
    padding: 0;
    float: left;
    list-style: none;
}

.legend-labels li {
    display: block;
    float: left;
    width: 50px;
    margin-bottom: 6px;
    text-align: center;
    font-size: 80%;
    list-style: none;
}

.legend-labels li span {
    display: block;
    float: left;
    height: 15px;
    width: 50px;
}

:global( path) {
    outline: 0 !important;
}