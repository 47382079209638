.title  {
    font-size: 18px;
    font-weight: bold;
    color: rgb(73, 119, 100);
    margin-bottom: 8px;
}

.container{
    padding:35px;
}

.container > p:last-child {
    margin-bottom: 0px;
}

.tableList > tr:nth-child(even) > .container {
    background-color: rgb(234,234,234);
}

.minitable {
    width: 100px;
}

.headred{
    color:white !important;
    background-color: rgb(183, 106, 95);
    font-weight: bold !important;
}

.cleanList{
    list-style-type:none;
    padding-left: 0;
}

.subtitle{
    margin-top: 20px;
    font-weight: bold;
}

.tabpanel{
    background-color: white;
}

.box {
    display: flex;
}

.imgBox {
    margin-left:25px;
    
}

.imgBox > img{
    max-width: 150px;
}

