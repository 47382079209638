.container > * {
  margin-bottom: 2em;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.titleContainer > *:first-child {
  margin-right: 1em;
  margin-left: 0;
}
