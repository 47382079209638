.paginationWrapper {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.toggleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationButton {
  border: none;
  background-color: transparent;
  transition: background-color 0.5s, color 0.5s;
  padding: 4px;
}

.paginationButton > * {
  pointer-events: none;
}

.paginationButton:hover {
  border: none;
  background-color: #b76a5f;
  color: white;
}

.currentPage {
  margin: 0 5px;
  max-height: 100%;
  width: auto;
}

.right {
  transform: rotate(180deg);
}

.listWrapper {
  position: relative;
  min-height: 56px;
}

.loadingOverlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 56px;
  z-index: 10000;
  background-color: rgba(217, 255, 204, 0.5);
}

.notFound {
  text-align: center;
}
