.individualModule1{
    text-align: left;
    margin-left: 0;
}

.titulo{
    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}


.titleAvaliar{
    text-align: left;
    color: #d8ce72;
    margin-left: 1rem;
}

.line{
    display: flex;
    margin-bottom: .5rem;
    
    
}
.iconeAtivo{
   margin-bottom: .2rem;
   margin-left: 1rem;
}

.back{
    color: black;
    margin-top: 4rem;
}
.back:hover{
    text-decoration: underline;
}

.link{
    color:black;
    font-size: 1.7rem;
}

.link:hover{
    color:black;
    font-size: 1.7rem;
}
