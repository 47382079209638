
.icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  cursor: pointer;
  color: #aaa;
  padding: 0.6rem;
}

.icon:before {
  content: "";
  display: block;
  position: absolute;
  background-color: transparent;
  cursor: pointer;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
}

.input {
  padding-left: 2.375rem;
}