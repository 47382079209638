.modules {
  text-align: center;
  font-size: 12rem;
  margin-bottom: 2rem;
}

.activeButton {
  margin: 5rem;
  opacity: 1;
}

.title {
  text-align: center;
  color: #b3695f;
  font-size: 3rem;
  margin: 3rem;
}

.activeButton:hover {
  opacity: 0.5;
}
 