@value colors: "../common/styles/colors.module.css";
@value accentColor, mainColor from colors;

@value barStyles: "./AppNavbar.module.css";
@value bottomBarHeight, contentPadding from barStyles;

.barContent {
  margin-left: auto;
  display: flex;
}

.bottomBar {
  background-color: #E5E5E5;
  min-height: bottomBarHeight;
  width: 100%;
  height: 100%;
}

.bottomBarLoggedIn {
  composes: bottomBar;
  min-height: 3.2em;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: contentPadding;
  padding-left: contentPadding;
}

@media only screen and (max-width: 790px) {
  .bottomBar {
    min-height: 3.2em;
    background-color: accentColor;
    margin-top: -0.05em;
  }

  .navbarLink {
    composes: navBarLink from "./AppNavbar.module.css";
  }

  .content {
    composes: content;
    padding-right: 0;
    padding-left: 0;
  }
}

.login {
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: calc(0.5 * contentPadding);
}

.register {
  background-color: #b3b3b3;
  padding-right: calc(1.5 * contentPadding);
  padding-left: calc(1.5 * contentPadding);
}

.registerLink {
  color: #333333;
  font-weight: bold;
  font-size: x-large;
}

.registerLink:hover {
  color: #000;
}

.registerText {
  font-weight: lighter;
}

.link:hover {
  color: rgb(38, 100, 42);
}

.searchBar {
  margin-top: auto;
  margin-bottom: auto;
}

.mobileSearchBar {
  width: 100%;
}

