.rowName {
  margin-bottom: 2em;
}
.listaTechSheet {
  width: 100%;
}
.apagarFichasTecnicas {
  display: flex;
  justify-content: center;
  align-items: center;
}
