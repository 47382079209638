@value colors: "../styles/colors.module.css";
@value accentColor from colors;

.formGroup {
  margin-bottom: 0.5rem;
}

.form {
  margin-right: 1em;
}

.forgotPasswordLink {
  text-align: right;
  margin-top: 0.25rem;
  font-size: 80%;
  font-weight: 400;
}

.loginButton {
  margin-top: auto !important;
  margin-bottom: 1rem !important;
}

.loginButtonContainer {
  height: 100%;
  display: flex;
}

.link {
  color: #497764 !important;
}