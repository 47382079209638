
.needs {
    display: block;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 4rem;
    width: 100%;
}

.whiteSpace {
    background: #ffffff;
    color: rgb(183, 106, 95);
    text-transform: uppercase;
    font-size: 53.4px;
    line-height: 64px;
    padding-left: 6rem;
}

.whiteSpaceSmall {
    composes: whiteSpace;
    padding-left: 2rem;
}

.whiteSpaceMobile {
    composes: whiteSpaceSmall;
    padding-left: 20px;
}

.brownSpace {
    background-color: rgb(218, 212, 212);
    display: flex;
    margin-bottom: 5rem;
    font-size: 71.1px;
    width: 100%;
    overflow: auto;
}

.brownSpaceSmall {
    composes: brownSpace;
    display: block;
}

.brownSpaceMobile {
    composes: brownSpace;
    flex-direction: column;
    margin-bottom: 1rem;
}

.question {
    padding-left: 6rem;
    text-transform: uppercase;
    color: #ffffff;
    width: 40%;
    margin-right: 4%;
}

.questionSmall {
    composes: question;
    padding-left: 2rem;
    font-size: 53.4px;
    margin-right: 0;
}

.questionMobile {
    composes: questionSmall;
    padding-left: 20px;
}

.options {
    display: flex;
    padding-top: 7rem;
    padding-bottom: 5rem;
}

.optionsSmall {
    display: flex;
    float: right;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-right: 10%;
}

.optionsMobile {
    display: block;
    padding-top: 2rem;
}

.option a {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    font-size: 25.1px;
    line-height: 30.1px;
    color: white;
    margin-right: 3rem;
    opacity: 1;
}

.option a:hover {
    opacity: 0.81;
}

.option:last-of-type a {
    margin-right: 0rem;
}

.option a img {
    margin-left: auto;
    margin-right: auto;
}

.option a img:hover {
    opacity: 0.5;
}

.optionsMobile a {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    font-size: 25.1px;
    line-height: 30.1px;
    color: white;
    margin-right: 0;
    margin-bottom: 1rem;
}

.optionsMobile a img {
    margin-left: auto;
    margin-right: auto;
}