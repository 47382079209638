.select {
  width: 40%;
}

.mover {
  display: inline-flex;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
}

.week {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.toggler label {
  border-radius: 5px;
  border: none;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.toggler input {
  display: none;
}

.toggler svg {
  transition: 0.5s ease-in-out;
}

.toggler label:hover svg {
  transform: rotateZ(-180deg);
}

.toggler input:checked + label svg {
  transform: rotateZ(-180deg);
}

.mealType {
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
  font-weight: 500;
  flex-direction: column;
}

.meals {
  border-bottom: 1px solid #333;
  flex-wrap: wrap;
  padding: 5px;
}

.link {
  margin: 5px;
}

.currentRange {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
}

.daysInWeek {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .mealType {
    border-right: none;
  }
}

@media (max-width: 500px) {
  .currentRange {
    font-size: 1rem;
  }

  .select {
    width: 90%;
  }

  .daysInWeek {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.createIcon {
  margin-right: 0.5rem;
}

.createButton {
  text-decoration: none;
  /* color: white; */
}

.mealDayTitle {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconEdit {
  color: green;
}
