.footer {
  margin-top: auto;
  background-color: #F5F5F5;
  box-sizing: border-box;
  min-height: 10vh;
  display: flex;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

@media only screen and (max-width: 1300px) {
  .footer {
    overflow-x: scroll;
    white-space:nowrap;
    width: 100vw;
    min-height: 13vh;
  }
}

.footer img:first-child {
  margin-left: 2em;
}

.footer img:nth-child(3) {
  margin-left: 0.4em;
}
