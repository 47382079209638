.list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  padding: 0 0.5em 0.5em 0.5em;
}

.buttonStyle {
  display: flex;
  align-items: center;
  background-color: #48a163;
  padding: 0.5em 0.5em;
  margin: 0 0.25em;
  color: white;
  border-color: transparent;
  border-radius: calc(0.25rem - 1px);
  border-style: outset;
  border-width: 2px;
}

.buttonStyle:focus {
  outline: 0;
}

.buttonStyle:hover {
  color: white;
  text-decoration: none;
}

@media (min-width: 768px) {
  .list {
    justify-content: flex-end;
    padding: 0;
  }
}
