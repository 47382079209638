.main {
  height: 100%;
  width: 100%;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.sidebarExpanded {
  padding-left: 0px;
}

.sidebarCollapsed {
  padding-left: 0px;
}

@media (min-width: 768px) {
  .sidebarCollapsed {
    padding-left: 50px;
  }
  .sidebarExpanded {
    padding-left: 220px;
  }
}
