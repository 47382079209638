.wrapper {
    height: 100%;
    width: 100%;
    text-align: left;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    letter-spacing: 0em;
}

.filters {
    margin-bottom: 2rem;
}

.filters label {
    font-size: 16px;
    color: rgb(183, 106, 95);
    line-height: 19.3px;
    letter-spacing: 0em;
    font-weight: bold;
    margin-right: 0.5rem;
}

.filters select {
    height: 19.3px;
    background-color: rgb(242, 242, 242);
    border: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 14px;
}

.typeSelector {
    margin-bottom: 3rem;
}

.typeSelector button {
    background-color: rgb(229,229, 229);
    color: rgb(128, 128, 128);
    margin-right: 10px;
    width: 40px;
    padding: 5px 0 5px 10px;
    border: 0;
}

.typeSelector button:last-of-type {
    transform: rotate(180deg);
}

.typeSelector img {
    margin-right: 10px;
}

.tableTitle h5 {
    font-weight: bold;
    color: rgb(73, 119, 100);
    font-size: 21.7px;
}

.tableTitle p {
    font-size: 0.95rem;
}

td.legendas{
    min-width: 2em;
}