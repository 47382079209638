.contentWrapper {
  justify-content: center;
  padding-top: 15px;
}

.optionAndLinkWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .optionAndLinkWrapper {
    justify-content: flex-end;
    padding: 0;
  }
}

.col {
  padding: 0;
}

.addLink {
  background-color: #B76A5F;
  color: white;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 1rem;
  
}

.add {
  width: 100%;
  margin: auto;
}

.add:hover {
  text-decoration: none;
  font-weight: bold;
}

.text {
  padding-left: 0.5rem;
}

.textSmall {
  display: none;
}
