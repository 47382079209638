.listCollapse {
  display: block;
  width: 100%;
}

.listItemClosed {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listItem {
  padding: 0.5em 0.5em;
  border-radius: calc(0.25em - 1px) calc(0.25em - 1px) 0 0;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #9ebc9e;
  background-color: white;
  transition: background-color 0.5s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listItem:hover {
  background-color: #e9ecde;
}

.itemName {
  padding: 0;
  margin: 0 auto 0 0;
}

.buttonStyle {
  background-color: #48a163;
  padding: 0.25em 0.25em;
  border-color: transparent;
  border-radius: calc(0.25rem - 1px);
  color: white;
}

.buttonIconStyle {
  background-color: transparent;
  border: 0;
  color: #c4c4c4;
}

.buttonIconStyle:focus {
  outline: 0;
}

.buttonStyle:focus {
  outline: 0;
}
