


.title {
    margin-top: 8px;
    font-size: 18px;
    font-weight: bold;
    color: rgb(73, 119, 100);
    margin-bottom: 18px;
    }
    
    .subtitle {
    margin-top: 18px;
    font-size: 18px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    margin-bottom: 18px;
    }
    
    .undertitle {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    margin-bottom: 8px;
    }
    
    .box {
    display: flex;
    }
    
    .text{
    margin-bottom: 8px;
    font-size: 18px;
    width: 95%;
    display: block;
    float: left;
    margin-bottom: 8px;
    }
    
    .textmargin{
    margin-bottom: 18px;
    font-size: 18px;
    width: 95%;
    display: block;
    float: left;
    margin-bottom: 18px;
    }

    .tabela{
        width: 100%;
    }

    .tabela1{
        width: 50%;
    }