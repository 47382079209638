.title {
  border-style: solid;
  border-width: 0 0 5px 0;
  padding-bottom: 0.25em;
  margin: 0;
}

.cardHeader {
  background-color: #4c6d4f !important;
  color: white;
}

.cardHeader h2 {
  margin: 0;
}

td {
  min-width: 100px;
}

/*
table thead th {
  transition: width 0.5s;
}

thead {
  background-color: #e9ecde;
}

thead th,
tbody td {
  border-color: #9ebc9e !important;
}

*/
.buttonStyle {
  background-color: #48a163;
  padding: 0.25em 0.25em;
  border-color: transparent;
  border-radius: calc(0.25rem - 1px);
  color: white;
}

/*
table tbody td.notExpanded {
  transition: border 0s 0.5s;
  padding: 0 !important;
  border: 0 !important;
}
*/

.notExpanded {
  display: none;
}
