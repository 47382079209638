@value mainColor: #4c6d4f;
@value secondaryColor: #65AA57;
@value errorColor: #e04257;

.alertNotification {
  width: 75% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.titleBar {
  display: flex;
  justify-content: space-between;
  background: mainColor;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto 0 0;
  color: #fff;
}

.title {
  padding: 0.5em 0;
  margin: 0 auto 0 0;
}

.date {
  align-self: center;
  color: #fff;
  margin-right: 0.5em;
}

.reportName {
  padding: 0.25em 0;
  border: solid mainColor;
  border-width: 0 0 2px;
  margin-bottom: 0;
}

.sectionList {
  list-style-type: none;
  padding: 0;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.sectionList > ul {
  padding-left: 0;
}

.section {
  display: flex;
  width: 100%;
  padding: 1em;
  justify-content: space-between;
}

.result {
  color: #4c5a69;
  margin-right: 3em;
  margin-bottom: auto;
  margin-top: auto;
  width: 1.5em;
}

.description {
  width: 90%;
}

@value progressBarHeight: 10px;

.progressBarParent {
  width: inherit;
  position: relative;
  
  height: progressBarHeight;
  -webkit-print-color-adjust: exact;
  margin-top: 0.5em;
}

.progressBar {
  background: errorColor;
  position: absolute;
  height: progressBarHeight;
  -webkit-print-color-adjust: exact;
}

.printSection {
  font-size: large;
}

.link {
  color: black;
}

.link:hover {
   color: rgb(153, 153, 153);
}