.titulo{
    padding: 3rem;
    color:#b3695f;
}

.main{
    margin-top: 1.5em;
    width: 100%;
    height: 70%;
}

@media(max-width: 900px){
.main{
    width: 100%;
    height: 100%;
    margin-left: 0%;
}
}