.wrapper {
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 5%;
}

.ring {
    display: inline-block;
    width: 200px;
    height: 200px;
}

.ring:after {
    content: " ";
    display: inline-block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 6px solid rgb(183, 106, 95);
    border-color: rgb(183, 106, 95) transparent rgb(183, 106, 95) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.text {
    margin-top: 2rem;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    letter-spacing: 0em;
    font-size: 2rem;
}

@keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}