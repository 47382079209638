.mealComponent {
  width: 100%;
  border: 1px solid grey;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 1px rgba(0, 0, 0, 0.18);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.73);
}

.input {
  width: 100%;
}
.resultados
{
  margin-top: 1em;
  width: 100%;
}
.element {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  border-top: 1px solid grey;
}

.element::before {
  content: '';
}

.item {
  padding: 10px 5px;
  cursor: pointer;
}

.item:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}

input::-webkit-input-placeholder {
  color: black; /*Change the placeholder color*/
  opacity: 0.4; /*Change the opacity between 0 and 1*/
}

.button,
.button:hover {
  text-align: left;
  width: 100%;
  color: black;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

