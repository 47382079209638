.individualModule1{
    text-align: left;
    margin-left: 0;
}

.titulo{
    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.titleConsultar{
    text-align: left;
    color: #8ec3ad;
    margin-left: 1rem;

}
.titlePlanear{
    text-align: left;
    color: #d6928a;
    margin-left: 1rem;

}
.titleAvaliar{
    text-align: left;
    margin-left: 1rem;
    color: #d8ce72;
}

.titleAdmin {
    composes: titleAvaliar;
    color: #729EA1;
}

.line{
    display: flex;
    margin-bottom: .5rem;
    
    
}
.iconeAtivo{
   margin-bottom: .2rem;
   margin-left: 1rem;
}

.back{
    color: black;
    margin-top: 4rem;
}
.back:hover{
    text-decoration: underline;
}

.link{
    color:black;
    font-size: 1.7rem;
}

.link:hover{
    color:black;
    font-size: 1.7rem;
}
