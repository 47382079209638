.checkboxParent {
  display: flex;
  margin: auto;
  margin-left: 1em;
  width: 10%;
  flex-direction: column;
}

.checkbox {
  margin-right: 0.5em;
}

.buttons {
  display: flex;
  padding-bottom: 2em;
  justify-content: center;
}

.firstButton {
  margin-right: 1.5em;
}

.button {
  background-color: #b76a5f;
  padding: 6px 12px;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.button:hover {
  color: white;
  background-color: #6c9c70;
}

.spacer {
  margin-left: 1.5em;
}

.print {
  margin: 0 !important;
  width: 100% !important;
  background-color: none !important;
}

.sectionTitle {
  padding: 0rem 3rem;
  text-align: center;
}

.pagination{
  margin:0 auto;
  text-align: center;
}

.paginationItem{
  height: 35px;
  line-height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  border-color: #d8ce6c;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  margin: 0 5px;
}

.paginationItem > a {
  text-decoration: none;
  color: #d8ce6c;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;

}

.enabledSection {
  color: white !important;
  background-color: #d8ce6c;
}

.concluir{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5px;    
}

.concluir a {
  font-size: 1rem;
  color: rgb(183, 106, 95);
  line-height: 14.5px;
}

.concluir img {
  margin-left: 5px;
}
