.searchBox,
.clearButton {
  border-radius: 0;
  border-top: 0;
  border-bottom: 1px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: #ced4da;
}

.inputWrapper {
  display: flex;
}

.clearButton {
  background-color: transparent;
  color: #ced4da;
}
