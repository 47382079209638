.buttonStyle {
  background-color: #48a163;
  padding: 0.25em 0.25em;
  border-color: transparent;
  border-radius: calc(0.25rem - 1px);
  color: white;
}

.buttonLinkStyle {
  color: white;
}

.buttonLinkStyle:hover {
  color: white;
}

.table {
  margin-top: 1rem;
}
