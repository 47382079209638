.root {
  padding: 0em;
}

.container {
  display: flex;
  justify-content: space-evenly;
}

.main {
  flex: 4
}

.spacer {
  flex: 2
}

.aux {
  flex: 2
}

.aux > * {
  margin-top: 3em;
  margin-bottom: 3em;
}

.aux > *:first-child {
  margin-top: 0em;
}

.aux > *:last-child {
  margin-bottom: 0em;
}

.link {
  display: flex;
  margin-bottom: 2em;
  justify-content: flex-end;
}

.link > *:last-child {
  margin-left: 1.5em;
  margin-top: auto;
  margin-bottom: auto;
}

.container > * {
  margin-bottom: 2em;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.titleContainer > *:first-child {
  margin-right: 1em;
}