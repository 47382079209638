.ingredientListItemWrapper {
  display: flex;
}

.title {
  margin: 0 !important;
}

.linkWrapper {
  margin-left: auto;
  color: black;
  text-decoration: none;
}

.linkContentWrapper {
  display: flex;
  align-items: center;
}

.linkWrapper:hover {
  text-decoration: none;
  color: #999999;
}

.linkContentWrapper p {
  margin: 0;
}

.linkContentWrapper svg {
  margin-right: 4px;
}
