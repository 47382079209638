@value colors: "../../components_lgp/common/styles/colors.module.css";
@value accentColor from colors;

.info {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    letter-spacing: 0em;
    text-align: left;
}

.description {
    padding: 198px 3rem 0 6rem;
    width: 50%;
}

.descriptionSmall {
    composes: description;
    padding: 0 0 0 2rem;
    width: 100%;
}

.descriptionMobile {
    composes: descriptionSmall;
    width: 100%;
    padding: 0 1rem 0 1rem;
}

.title {
    text-transform: uppercase;
    font-size: 44.8px;
    color: rgb(183, 106, 95);
    line-height: 53.8px;
}

.spareDescription {
    font-size: 1rem;
    color: rgb(51,51,51);  
    line-height: 1.2rem;
}

.knowMore {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 4rem;
}

.knowMoreMobile {
    composes: knowMore;
    padding-right: 0rem;
}

.knowMore a {
    font-size: 1rem;
    color: rgb(183, 106, 95);
    line-height: 14.5px;
    padding: 0;
}

.knowMore a img {
    margin-left: 5px;
}

.image img {
    width: 427px;
    border-radius: 50%;
}

.imageSmall img {
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.image {
    width: 50%;
    padding-left: 3rem;
    padding-top: 53px;
}

.imageSmall {
    padding-top: 10%;
    width: 100%;
}

.imageMobile {
    width: 100%;
}