.cancelButton {
  margin-right: 0.5em;
  color: red;
  background-color: white;
}

.cancelButton:hover {
  color: white;
  background-color: red;
}

.saveButton {
  color: blue;
  background-color: white;
}

.saveButton:hover {
  color: white;
  background-color: blue;
}

.linhaEmenta {
  height: 100%;
}
.apagarEmentaBotao {
  height: 80%;
}
.colApagarEmentaBotao {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowEmentas {
  width: 100%;
}
.adicionarEmentaBotao {
  margin-top: 0.5em;
}
.titleBg {
  background-color: #4c6d4f;
}
.title {
  padding: 15px;
  color: white;
  margin: 0 auto 0 0;
}
.rowGuardarTudo {
  margin-right: 2em;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: space-between;
}

.savingDisplay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}
.spinnerSaving
{
  margin-right: 1em;
}
.grupoBaixo
{
  display: flex;
  align-items: center;
  justify-content: center;
}
