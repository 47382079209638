@value innerPadding: 0.76rem;

.button {
  background: #8CC3AC;
}

.leftPad {
  padding-right: innerPadding;
}

.rightPad {
  padding-left: innerPadding;
}

.spinner {
  margin-left: 0.5em;
}