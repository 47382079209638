.title {
  border-style: solid;
  border-width: 0 0 5px 0;
  padding-bottom: 0.25em;
  margin: 0;
}

.cardHeader {
  background-color: #4c6d4f !important;
  color: white;
}

.cardHeader h2 {
  margin: 0;
}

.graph {
  margin: 0 auto;
}
