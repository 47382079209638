@media (max-width:600px){
    .imgBox{
        display: none;
    }
}

.container > div:first-child {
    font-size: 18px;
    font-weight: bold;
    color: rgb(73, 119, 100);
    margin-bottom: 8px;
}

.container {
    padding:35px;
}

.tableList > tr:nth-child(even) > td {
    background-color: rgb(234,234,234);
}

.box {
    display: flex;
}

.imgBox {
    margin-left: 25px;
}
