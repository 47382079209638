.tableWrapper {
    width: 100%;
    overflow: auto;
}

.tableWrapper td {
    border: 0;
}

.diverTable {
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 10px;
}

.diverTableSmall {
    composes: diverTable;
    font-size: 8px;
}

.diverTableMobile {
    composes: diverTable;
    font-size: 7px;
}

.tableHeader td {
    background-color: rgb(183, 106, 95);
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    line-height: 18.1px;
    letter-spacing: 0em;
    padding: 0.5rem 0 0.5rem 1rem;
}

.tableHeader td:first-of-type {
    min-width: 40px;
    width: 30px;
    padding-right: 1rem;
    padding-top: 1.5rem 1rem;
}


.diverTable tr:nth-of-type(2) td:last-of-type, .total {
    min-width: 40px;
    text-align: center;
}

.diverTable tr:nth-of-type(2){
    font-size: 1.4em;
}

.days td {
    background-color: white;
    color: rgb(128, 128, 128);
    padding: 2px 0;
    margin: 0;
    min-width: 4px;
    max-width: 5px;
    text-align: center;
}

.tableHeader .whiteSpace, .component .whiteSpace {
    background-color: white;
    min-width: 0px;
    width: 3px;
    padding: 0;
}

.days td:last-of-type {
    background-color: rgb(183, 106, 95);
    color: white;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
}

.grids {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    background-color: white;
}

.emptyrow {
    height: 3px;
    min-height: 0px;
    background-color: white;
}

.component .grids .day span {
    display: block;
}

.component {
    font-size: 1.2em;
    background-color: white;
}

.componentCell{
    padding: 0;
}

.day {
    max-width: 21px;
    width: 21px;
    text-align: center;
}

.dayWrapper {
    background-color: #F2F2F2;
    margin-right: 4px;
    padding-left: 5px;
    padding-bottom: 5px;
}

.dayWrapper:last-of-type {
    margin-right: 0;
}

.noValues {
    text-align: center;
    font-size: 1.5em;
}

.emptyRowValue {
    padding: 3rem 0;
}

.componentName {
    text-align: center;
}

td.legendas {
    min-width: 2em;
}
