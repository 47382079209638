.title {
  margin-top: 8px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(73, 119, 100);
  margin-bottom: 3rem;
}

.subtitle {
  margin-top: 18px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-bottom: 18px;
}
.bold{
  font-weight: bold;
  width: 6%;
  background-color: red;
}

.undertitle {
  margin-bottom: 8px; 
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.box {
  display: flex;
  margin-bottom: 1rem;
}

.text{
  margin-bottom: 8px;
  font-size: 18px;
  width: 96%;
  display: block;
  float: left;
}

.textmargin{
  margin-bottom: 18px;
  font-size: 18px;
  width: 95%;
  display: block;
  float: left;
}

.table1 th,
.table2 th,
.table3 th{
  background-color: #d6928a;
  color: white;
}

.table1 td,
.table2 td,
.table3 td{
  background-color: #fafaf6;
}

.table1{
  margin-left: 15%;
  margin-bottom: 2rem;
}

.table2{
  margin-left: 16%;
  margin-bottom: 2rem;
}

.table3{
  margin-left: 40%;
  margin-bottom: 2rem;
}

.table1 table,
.table2 table,
.table3 table,
.table1 th,
.table2 th,
.table3 th,
.table1 td,
.table2 td,
.table3 td{
  border: 4px solid white;
}

@media only screen and (max-width: 790px) {
  .table1{
    margin-bottom: 2rem;
    margin-left: -1rem;

  }
  
  .table2{
    margin-bottom: 2rem;
    margin-left: -1rem;

  }
  
  .table3{
    margin-bottom: 2rem;
    margin-left: -1rem;

  }
  
 
}
