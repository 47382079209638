.mainContainer{
    right: 12rem;
    width: 50%;
    margin-right: 50%;
}

.login{
    margin-left: 85%;
    bottom: 100px;
}
.button{
    margin-left: 79%;
    color: #d6928a;
    background-color: transparent;
    border: none;
    
}
.button:hover{
    background-color: transparent;
    color: #d6928a;
    opacity: .5;
}
.registo{
    color: #d6928a;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.name{
    margin-top: 4rem;
}

.image:hover{
    opacity: .50;

}

.image{
    margin-left: .5rem;
}
.link{
    font-size: 75%;
    margin-left: 70%;
}

.blueLink{
    color: #8ec3ad;
}

.blueLink:hover{
    color: #8ec3ad;

}



.termos{
    margin-left: 10rem;
    margin-top: -4rem;
}


@media(max-width: 900px){
    .mainContainer{
        width: 100%;
        margin-right: 0;
    }
    .login{
        margin-left: 0rem;
        margin-top: 10rem;
    }
    .button{
        margin-left: 0rem;
    }

    .link{
        margin-top: -2rem;
    }
}
