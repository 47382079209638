.CardTitle {
  font-weight: bold;
  font-size: xx-large;
}

.row {
  justify-content: space-between;
  align-items: center;
  text-align: center;
  display: flex;
}

.text {
  font-size: 0.9em;
}

.card {
  flex: 1;
}

.card:first-child {
  margin-right: 16px;
}