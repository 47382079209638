.sidebar {
  min-width: 50px;
  max-width: 50px;
  position: fixed;
  z-index: 600;
  background: #4c6d4f;
  height: 100%;
  left: 0;
  color: #fff;
  transition: 0.3s;
  justify-content: start;
  overflow-x: hidden;
}

.sidebar.is-open {
  min-width: 220px;
  max-width: 220px;
  text-align: start;
  justify-content: start;
}

.sidebar li a {
  white-space: nowrap;
  align-items: center;
}

.sidebar button {
  white-space: nowrap;
}

.sidebar.is-open li a {
  justify-content: start;
}

.sidebar-ul {
  width: 220x;
}

.nav-item {
  width: 100%;
}

.nav-item a {
  display: flex;
  padding: 12px 16px;
  min-height: 50px;
}

.nav-link {
  color: white;
}

.nav-link:hover {
  background: #65aa57;
  color: white;
}

.side-menu {
  display: inline-block;
}

.sidebar.is-open .closeBtn {
  width: 220px;
}

.closeBtn {
  background: transparent;
  color: white;
  border: none;
  padding: 12px 16px;
  min-width: 50px;
  min-height: 50px;
  position: fixed;
  bottom: 0;
  width: 50px;
  display: flex;
  align-items: center;
  transition: width 0.3s;
}

.closeBtn .icon {
  transition: 0.3s ease-in-out;
}

.sidebar:not(.is-open) .closeBtn .icon {
  transform: rotate(180deg);
}

.collapseText  {
  white-space: nowrap;
}

.closeBtn:focus {
  outline: 0;
}

.closeBtn:hover {
  background-color: 0;
  background-color: #65aa57;
}

.sidebar.is-open .icon {
  margin-right: 10px;
}

.icon {
  min-height: 16px;
  max-height: 16px;
  min-width: 16px;
  max-width: 16px;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

@media (max-height: 440px) {
  .closeBtn {
    position: relative;
  }
}
