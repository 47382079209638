@value colors: "../common/styles/colors.module.css";
@value accentColor from colors;

@value topBarHeight: 3.5vh;
@value bottomBarHeight: calc(3 * topBarHeight);
@value contentPadding: 2em;


.navBar {
  padding: 0;
  height: 11.3rem;
}

.navBarLoggedIn {
  composes: navBar;
  height: 6rem;
}

.navBarMobile {
  background-color: accentColor;
  height: calc(bottomBarHeight + contentPadding);
}

.topBar {
  background-color: accentColor;
  width: 100%;
  padding-right: contentPadding;
}

.barContent {
  margin-left: auto;
  display: flex;
}

.barContentMobile {
  display: flex;
  flex-direction: column;
}

.navBarBrand {
  color: white;
  position: absolute;
  display: flex;
  left: 1em;
  max-width: 30em;
}

.navBarToggler {
  margin-left: auto;
  border: 0;
}

.navBarToggler:focus {
  outline: 0;
}

.navBarItemList .nav-item {
  display: flex;
  direction: row;
}

.navBarLink {
  color: #3D4441 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
}

.navBarLink:hover {
  color: #000000 !important;
}

.navBarLinkActive {
  color: #FFFFFF !important;
}

.navBarLinkActive:hover {
  color: #DDDDDD !important;
}

.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.logoText {
  font-family: 'Roboto Condensed', sans-serif;
  display: block;
  width: 14em;
  color: #808080;
  font-style: italic;
  font-size: medium;
  font-weight: 300;
  white-space: normal;
  margin-left: contentPadding;
  margin-top: auto;
  margin-bottom: 3rem;
  padding-top: 2rem;
}

.logoTextLoggedIn {
  composes: logoText;
  margin-bottom: 0;
}

.collapse {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 791px) {
  .collapse {
    height: auto;
    min-width: 100vw !important;
    margin-left: -1rem;
  }

  .collapse > * {
    padding-left: 0.5rem;
  }

  .divider {
    display: none;
  }
}