.title {
  color: white;
  margin: 0;
}

.titleRow {
  background-color: #4c6d4f;
}

.titleCol {
  padding: 15px;
}

.formCol {
  padding: 15px;
}

.retryAlert:hover {
  cursor: pointer;
}

.list {
  list-style-type: none;
  padding: 0;
}

.listItem {
  align-items: flex-end;
}

.selectedIngredientName,
.selectedTechnicalSheetName {
  margin: 0;
}

.formSearch {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 1em !important;
}
